import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
// materials
import { Theme } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { SxProps } from '@material-ui/system';
import { useSnackbar } from 'notistack5';
import { FC, useState } from 'react';
import { useForm } from "react-hook-form";
import { fCurrency, fNumber } from 'utils/formatNumber';
import useLocales from '../../hooks/useLocales';
import { getInvoiceSimulation } from '../../api/repositories/invoiceRepository';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// components
import Page from '../../components/Page';
// hooks
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------


interface StateModel {
  loading: boolean;
  error: string;
  invoice: InvoiceModel;
}

interface InvoiceModel {
  totalInvoiceAmount: number;
  totalOnasAmount: number;
  totalSonedeAmount: number;
}

const InvoiceSimulation: FC = (): JSX.Element => {
  const [state, setState] = useState<StateModel>({
    loading: false,
    error: '',
    invoice: {
      totalInvoiceAmount: 0,
      totalOnasAmount: 0,
      totalSonedeAmount: 0
    },
  });
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      consumption: 0
    },
    shouldUnregister: false
  });
  const onSubmit = (data: any) => {
    getInvoiceSimulation(data)
      .then((response) => {
        setState((state) => ({ ...state, invoice: response }));
        enqueueSnackbar('Save success', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      });
  };
  if (state.loading) {
    return <div>Loading....</div>;
  }
  if (state.error !== '') {
    return <div>Error</div>;
  }
  if (!state.loading && state.invoice === undefined) {
    return <div>Not Data</div>;
  }

  return (
    <Page title="Invoice Simulation | Water-Sec">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={translate('pages.invoice_simulation.title')}
          links={[]}
        />

        <Card sx={{ p: 3 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <TextField
              fullWidth
              {...register("consumption")}
              name="consumption"
              type="number"
              label={translate('pages.invoice_simulation.form.your_consumption')}
            />
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)}>
                {translate('pages.invoice_simulation.form.calculate')}
              </LoadingButton>
            </Box>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{translate("pages.invoice_simulation.cards.total_amount")}</Typography>
                <Typography variant="h3">{fCurrency(state.invoice?.totalInvoiceAmount)} TND</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{translate("pages.invoice_simulation.cards.sonede_amount")}</Typography>
                <Typography variant="h3">{fCurrency(state.invoice?.totalSonedeAmount)} TND</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{translate("pages.invoice_simulation.cards.onas_amount")}</Typography>
                <Typography variant="h3">{fCurrency(state.invoice?.totalOnasAmount)} TND</Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default InvoiceSimulation;

