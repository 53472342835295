// material
import {
  Box,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer
} from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/styles';
// utils
import useLocales from 'hooks/useLocales';
import { fNumber, fPercent } from '../../../utils/formatNumber';
import Scrollbar from '../../Scrollbar';


// ----------------------------------------------------------------------


export default function SensorsConsumptionList(props: { data: Array<{ sensor: string, consumption: number }>, title: string }) {
  const { translate } = useLocales();
  const totalConsumption = (props?.data || []).reduce((accumulator, currentValue) => accumulator + currentValue.consumption, 0) || 1;
  (props?.data || []).sort((firstElement, secondElement) => {
    if (firstElement.consumption > secondElement.consumption) {
      return -1;
    }
    if (firstElement.consumption < secondElement.consumption) {
      return 1;
    }
    return 0;
  });

  const StyledTableRow = withStyles((theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <Card>
      <CardHeader title={props.title} subheader={translate("pages.analytics.chart.subheader")} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate("pages.reporting.sensors_consumption_list.sensor")}</TableCell>
                <TableCell align="right" size="small">{translate("pages.reporting.sensors_consumption_list.total")} (L)</TableCell>
                <TableCell align="right" size="small">{translate("pages.reporting.sensors_consumption_list.percentage")} (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.data.map((row) => (
                <StyledTableRow key={row.sensor}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle2"> {row.sensor}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right" size="small">{fNumber(row.consumption)}</TableCell>
                  <TableCell align="right" size="small">{fPercent(row.consumption / (totalConsumption > 0 ? totalConsumption : 1) * 100)}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
