import { noCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';

// material
import {
  Box,
  List,
  Badge,
  Button,
  Divider,
  Typography,
  ListItemText,
  ListItemButton
} from '@material-ui/core';
// utils
import { parseISO } from 'date-fns';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { fToNow } from '../../utils/formatTime';
import { useSelector } from '../../redux/store';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { SystemNotification, SystemNotificationState } from '../../@types/notifications';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

function renderContent(notification: SystemNotification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.data.type}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.data.description)}
      </Typography>
    </Typography>
  );
  return {
    title
  };
}

function NotificationItem({ notification }: { notification: SystemNotification }) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      to={`${PATH_DASHBOARD.management.notification.root}/${notification.id}`}
      disableGutters
      key={notification.id}
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        '&:not(:last-of-type)': { mb: '1px' },
        ...(!notification.isRead && {
          bgcolor: 'action.selected'
        })
      }}
    >

      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(parseISO(notification.createdAt))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { notifications } = useSelector((state: { notifications: SystemNotificationState }) => state.notifications);
  const totalUnRead = (notifications || []).filter((item) => !item.isRead).length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>
        <Divider />
        {
          (notifications || []).length > 0 && (
            <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
              <List
                disablePadding
              >
                {(notifications || []).slice(0, 3).map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} />
                ))}
              </List>
            </Scrollbar>
          )
        }
        <Divider />
        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to={PATH_DASHBOARD.management.notification.root}>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
