import _ from 'lodash';
// material
import { Grid, Container, TextField, Button, Card, CardHeader, Autocomplete, CardContent } from '@material-ui/core';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { DesktopDatePicker } from '@material-ui/lab';
import { FC, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { subDays, format, subMonths } from 'date-fns';
// hooks
import { useAnalyticStatsQuery } from 'api/queriesHooks/useAnalyticStatsQuery';
import { useFloorsQuery } from 'api/queriesHooks/useSensorsQuery';
import {
  AnalyticStatsParamsInterface,
  ResponseAnalyticsData
} from 'api/repositories/getAnalyticStats';
import useSettings from '../../hooks/useSettings';
import useLocales from '../../hooks/useLocales';
import { FloorForForm, Building, Floor } from '../../@types/sensors';
// components
import Page from '../../components/Page';

import {
  AnalyticsConsumption,
  TotalConsumption,
  AverageConsumptionPerDay,
  AverageConsumptionPerUse
} from '../../components/_dashboard/general-analytics';

// ----------------------------------------------------------------------

const PeriodATextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#5bb6e6',
      },
    },
    '& label.Mui-focused': {
      color: '#5bb6e6',
    },
  },
})(TextField);

const PeriodBTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#52ab32',
      }
    },
    '& label.Mui-focused': {
      color: '#52ab32',
    },
  },
})(TextField);

export default function Comparison() {
  const { themeStretch } = useSettings();

  const [state, setState] = useState<PeriodFormFilterStateInterface>({
    start_date: subDays(new Date(), 30),
    end_date: new Date(),
    sensors: [],
    reference_start_date: subMonths(new Date(), 2),
    reference_end_date: subMonths(new Date(), 1),
    reference_sensors: [],
    action: "compare"
  });

  const state2paramsQuery = (
    state: PeriodFormFilterStateInterface
  ): AnalyticStatsParamsInterface => ({
    start_date: state.start_date ? format(state.start_date, 'dd-MM-yyyy') : null,
    end_date: state.end_date ? format(state.end_date, 'dd-MM-yyyy') : null,
    sensors: state.sensors,
    reference_start_date: state.reference_start_date ? format(state.reference_start_date, 'dd-MM-yyyy') : null,
    reference_end_date: state.reference_end_date ? format(state.reference_end_date, 'dd-MM-yyyy') : null,
    reference_sensors: state.reference_sensors,
    action: state.action,
    locations: JSON.parse(localStorage.getItem("selected_locations") || "[]"),
  });
  const buildings = _.get(
    useFloorsQuery({ locations: JSON.parse(localStorage.getItem("selected_locations") || "[]") }),
    ['data', 'data'],
    []
  ) as Array<Building>;
  const analyticStatsData = _.get(
    useAnalyticStatsQuery(state2paramsQuery(state)),
    ['data', 'data'],
    {}
  ) as ResponseAnalyticsData;

  return (
    <Page title={`Analytics | ${process.env.REACT_APP_NAME || 'WaterSec'}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PeriodFormFilter
              floors={buildings}
              onChange={(params) => setState((state) => ({ ...state, ...params }))}
              default={state}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TotalConsumption tagsStats={analyticStatsData.data?.stats}
              totalConsumptionData={analyticStatsData.data?.allTotal}
              referenceTotalConsumptionData={analyticStatsData.reference_data?.allTotal}
              referenceTagsStats={analyticStatsData.reference_data?.stats}
              action="compare"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AverageConsumptionPerDay
              averageConsumptionPerDay={analyticStatsData.data?.allAveragePerDay}
              tagsStats={analyticStatsData.data?.stats}
              referenceAverageConsumptionPerDay={analyticStatsData.reference_data?.allAveragePerDay}
              referenceTagsStats={analyticStatsData.reference_data?.stats}
              action="compare"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AverageConsumptionPerUse
              averageConsumptionPerUse={analyticStatsData.data?.allAveragePerUse}
              tagsStats={analyticStatsData.data?.stats}
              referenceAverageConsumptionPerUse={analyticStatsData.reference_data?.allAveragePerUse}
              referenceTagsStats={analyticStatsData.reference_data?.stats}
              action="compare"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AnalyticsConsumption consumption={{ dates: analyticStatsData.data?.dates, consumptions: analyticStatsData.data?.consumptions }}
              referenceConsumption={{ dates: analyticStatsData.reference_data?.dates, consumptions: analyticStatsData.reference_data?.consumptions }}
              action="compare"
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

interface PeriodFormFilterPropsInterface {
  floors: Array<Building>;
  onChange: (period: PeriodFormFilterStateInterface) => void;
  default: PeriodFormFilterStateInterface;
}

interface PeriodFormFilterStateInterface {
  start_date: Date | null;
  end_date: Date | null;
  sensors: Array<number>;
  reference_start_date: Date | null;
  reference_end_date: Date | null;
  reference_sensors: Array<number>;
  action: string;
}

const PeriodFormFilter: FC<PeriodFormFilterPropsInterface> = (props): JSX.Element => {
  const [state, setState] = useState<PeriodFormFilterStateInterface>(props.default);
  const { translate } = useLocales();
  const floors = (props?.floors).reduce((accumulator: Array<FloorForForm>, current: Building) => {
    const floor = (current.floors || []).map((floor: Floor) => ({
      buildingId: current.id,
      buildingName: current.name,
      floorId: floor.id,
      floorName: floor.name
    } as FloorForForm));
    accumulator.push(...floor);

    return accumulator;
  }, []);
  return (
    <>
      <Card>
        <CardHeader title={translate('pages.analytics.comparison.title')} subheader={translate('pages.analytics.comparison.subheader')} />
        <CardContent>
          <Grid direction="row" container spacing={3} wrap="wrap" alignItems="center" >
            <Grid item xs={3} md={3} lg={3}>
              <DesktopDatePicker
                mask="__-__-____"
                inputFormat="dd-MM-yyyy"
                label={`${translate("pages.analytics.form.start_date")} A`}
                value={state.start_date}
                maxDate={new Date()}
                onChange={(newValue) => {
                  setState((state) => ({ ...state, start_date: newValue }));
                }}
                renderInput={(params) => <PeriodATextField fullWidth {...params} margin="normal" />}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <DesktopDatePicker
                mask="__-__-____"
                label={`${translate("pages.analytics.form.end_date")} A`}
                inputFormat="dd-MM-yyyy"
                value={state.end_date}
                minDate={state.start_date}
                maxDate={new Date()}
                onChange={(newValue) => {
                  setState((state) => ({ ...state, end_date: newValue }));
                }}
                renderInput={(params) => <PeriodATextField fullWidth {...params} margin="normal" />}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <Autocomplete
                ListboxProps={{ style: { maxHeight: "15rem" } }}
                multiple
                id="sensors"
                limitTags={3}
                groupBy={(option: FloorForForm) => option.buildingName}
                options={floors || []}
                isOptionEqualToValue={(option: FloorForForm, value: FloorForForm) => option.floorId === value.floorId}
                getOptionLabel={(floor) => `${floor.floorName}`}
                filterSelectedOptions={true}
                renderInput={(params) => (
                  <PeriodATextField
                    {...params}
                    label={`${translate("pages.analytics.form.tags")} A`}
                    placeholder={`${translate("pages.analytics.form.tags_placeholder")} A`}
                  />
                )}
                onChange={(event, value) => {
                  setState((state) => ({
                    ...state,
                    sensors: value.map((item) => item.floorId)
                  }));
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" container spacing={3} wrap="wrap" alignItems="center" justifyContent="center">
            <Grid item xs={3} md={3} lg={3}>
              <DesktopDatePicker
                mask="__-__-____"
                inputFormat="dd-MM-yyyy"
                label={`${translate("pages.analytics.form.start_date")} B`}
                value={state.reference_start_date}
                maxDate={new Date()}
                onChange={(newValue) => {
                  setState((state) => ({ ...state, reference_start_date: newValue }));
                }}
                renderInput={(params) => <PeriodBTextField fullWidth {...params} margin="normal" />}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <DesktopDatePicker
                mask="__-__-____"
                label={`${translate("pages.analytics.form.end_date")} B`}
                inputFormat="dd-MM-yyyy"
                value={state.reference_end_date}
                minDate={state.reference_start_date}
                maxDate={new Date()}
                onChange={(newValue) => {
                  setState((state) => ({ ...state, reference_end_date: newValue }));
                }}
                renderInput={(params) => <PeriodBTextField fullWidth {...params} margin="normal" />}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <Autocomplete
                ListboxProps={{ style: { maxHeight: "15rem" } }}
                multiple
                id="reference_sensors"
                limitTags={3}
                groupBy={(option: FloorForForm) => option.buildingName}
                options={floors || []}
                isOptionEqualToValue={(option: FloorForForm, value: FloorForForm) => option.floorId === value.floorId}
                getOptionLabel={(floor) => `${floor.floorName}`}
                filterSelectedOptions={true}
                renderInput={(params) => (
                  <PeriodBTextField
                    {...params}
                    label={`${translate("pages.analytics.form.tags")} B`}
                    placeholder={`${translate("pages.analytics.form.tags_placeholder")} B`}
                  />
                )}
                onChange={(event, value) => {
                  setState((state) => ({
                    ...state,
                    reference_sensors: value.map((item) => item.floorId)
                  }));
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <Button startIcon={<CompareArrowsIcon />} variant="contained" size="large" onClick={() => { setState((state) => ({ ...state, action: "compare" })); props.onChange(state); }}>
                {translate('pages.analytics.comparison.button')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
