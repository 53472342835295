import axiosInstance from 'utils/axios';

export interface AnalyticStatsInterface {
  dates: Array<string>;
  consumptions: Array<TagConsumption>;
  stats: Array<TagStats>;
  allTotal: number;
  allAveragePerUse: number;
  allAveragePerDay: number;
}

export interface TagConsumption {
  tag: string;
  consumptions: Array<number>;
}

export interface TagStats {
  tag: string;
  stats: Stats;
}

interface Stats {
  total: number;
  average_per_day: number;
  average_per_use: number;
}

export interface ResponseAnalyticsData {
  data: AnalyticStatsInterface,
  reference_data: AnalyticStatsInterface
}

export interface InvoiceType {
  total_amount: number,
  onas_amount: number,
  sonede_amount: number
}

export interface ReportingStatsInterface {
  total: number;
  average_per_day: number;
  average_per_use: number;
  bar_chart_data: Array<{ consumption: number; date: string }>;
  pie_chart_data: Array<{ consumption: number; sensor: string }>;
  consumption_price: number;
}

export interface ReportingPieChartsInterface {
  sensorsPieChartData: Array<{ consumption: number; sensor: string }>;
  tagsPieChartData: { [key: string]: Array<number> };
  subCategoriesPieChartData: { [key: string]: Array<number> };
  mainCategoriesPieChartData: { [key: string]: Array<number> };
}

export interface ResponseReportingData {
  data: AnalyticStatsInterface;
  pieChartsData: ReportingPieChartsInterface;
  invoice: InvoiceType;
}

export interface AnalyticStatsParamsInterface {
  start_date: string | null;
  end_date: string | null;
  sensors: Array<number>;
  reference_start_date: string | null;
  reference_end_date: string | null;
  reference_sensors: Array<number>;
  action: string;
  locations: Array<string>;
}

export const getAnalyticStats = async (
  params: AnalyticStatsParamsInterface
): Promise<ResponseAnalyticsData> => axiosInstance.get(`/stats/analytics`, { params });

export const exportData = async (
  params: AnalyticStatsParamsInterface
): Promise<{ data: Blob }> => axiosInstance.get(`/stats/analytics-export`, { ...params, responseType: 'blob' });

export const getReportingStats = async (
  params: AnalyticStatsParamsInterface
): Promise<ResponseReportingData> => axiosInstance.get(`/stats/reporting`, { params });

export const downloadReport = async (
  params: AnalyticStatsParamsInterface
): Promise<{ data: Blob }> => axiosInstance.get(`/stats/reporting-download`, { ...params, responseType: 'blob' });