import { Icon } from '@iconify/react';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import outlineNotificationsOff from '@iconify/icons-ic/outline-notifications-off';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Tooltip,
  Checkbox,
  IconButton
} from '@material-ui/core';
//
import useLocales from 'hooks/useLocales';
import { MHidden } from '../../@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: 84,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2)
}));

// ----------------------------------------------------------------------

type NotificationToolbarProps = {
  notifications: number;
  selectedNotifications: number;
  onSelectAll: VoidFunction;
  onDeselectAll: VoidFunction;
  handleSelectedMarkAsRead: VoidFunction;
  handleSelectedDelete: VoidFunction;
};

export default function NotificationToolbar({
  notifications,
  selectedNotifications,
  onSelectAll,
  onDeselectAll,
  handleSelectedMarkAsRead,
  handleSelectedDelete,
  ...other
}: NotificationToolbarProps) {
  const handleSelectChange = (checked: boolean) => (checked ? onSelectAll() : onDeselectAll());
  const selectedAllNotificaions = selectedNotifications === notifications && notifications > 0;
  const selectedSomeNotificaions = selectedNotifications > 0 && selectedNotifications < notifications;
  const { translate } = useLocales();
  const deleteTitle = translate('pages.notifications.delete');
  const markAsReadTitle = translate('pages.notifications.mark_as_read');

  return (
    <RootStyle {...other}>
      <MHidden width="smDown">
        <Checkbox
          checked={selectedAllNotificaions}
          indeterminate={selectedSomeNotificaions}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleSelectChange(event.target.checked)
          }
        />
        {
          (selectedSomeNotificaions || selectedAllNotificaions) && (
            <>
              <Tooltip title={deleteTitle}>
                <IconButton onClick={handleSelectedDelete}>
                  <Icon icon={trash2Fill} width={20} height={20} />
                </IconButton>
              </Tooltip>
              <Tooltip title={markAsReadTitle}>
                <IconButton onClick={handleSelectedMarkAsRead}>
                  <Icon icon={outlineNotificationsOff} width={20} height={20} />
                </IconButton>
              </Tooltip>
            </>
          )
        }

      </MHidden>

      <Box sx={{ flexGrow: 1 }} />

      {/* <MHidden width="smDown">
        <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          <Typography variant="body2" sx={{ mx: 2, color: 'text.secondary' }}>
            1 - {notifications} of {notifications}
          </Typography>
          <Tooltip title="Next page">
            <IconButton>
              <Icon icon={arrowIosBackFill} width={20} height={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Previous page">
            <IconButton>
              <Icon icon={arrowIosForwardFill} width={20} height={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </MHidden> */}
    </RootStyle>
  );
}
