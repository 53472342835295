// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import trendingUpFill from '@iconify/icons-eva/trending-up-fill';
import trendingDownFill from '@iconify/icons-eva/trending-down-fill';
// utils
import useLocales from 'hooks/useLocales';
import { TagStats } from 'api/repositories/getAnalyticStats';
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

// ----------------------------------------------------------------------

export default function TotalConsumption(props: {
  totalConsumptionData: number,
  referenceTotalConsumptionData?: number,
  tagsStats: Array<TagStats>,
  referenceTagsStats?: Array<TagStats>,
  action: string
}) {
  const { translate } = useLocales();
  const theme = useTheme();
  let percent = 0;
  if (props?.referenceTotalConsumptionData && props?.action === "compare") {
    percent = ((props?.totalConsumptionData / props?.referenceTotalConsumptionData) * 100) - 100;
  }
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{translate("pages.analytics.cards.total_consumption")}</Typography>
        {
          props?.action === "compare" && (
            <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
              <IconWrapperStyle
                sx={{
                  ...(percent > 0 && {
                    color: 'error.main',
                    bgcolor: alpha(theme.palette.error.main, 0.16)
                  })
                }}
              >
                <Icon
                  width={16}
                  height={16}
                  icon={percent > 0 ? trendingUpFill : trendingDownFill}
                />
              </IconWrapperStyle>
              <Typography component="span" variant="subtitle2">
                {percent > 0 ? translate('pages.analytics.comparison.increase') : translate('pages.analytics.comparison.decrease')}
                {fShortenNumber(Math.abs((props?.totalConsumptionData ?? 0) - (props?.referenceTotalConsumptionData ?? 0)))} L
              </Typography>
            </Stack>
          )
        }
        <Typography variant="h3">{fShortenNumber(props.totalConsumptionData)} L</Typography>
        {
          (props?.tagsStats || []).map((tagStat) => (
            <Typography key={tagStat.tag} variant="subtitle2">{`${translate(`tags.${tagStat.tag}`)} ${fShortenNumber(tagStat.stats.total)} ${translate('common.liters')}`}</Typography>
          ))
        }
        {
          props.action === "compare" && ((props?.referenceTagsStats || []).map((tagStat, index) => (
            <Typography key={`${tagStat.tag}_${index}`} variant="subtitle2">{`${fShortenNumber(tagStat.stats.total)} ${translate('common.liters')}`}</Typography>
          )))
        }

      </Box>
    </Card>
  );
}
