import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@material-ui/core';
import useLocales from 'hooks/useLocales';
import { useTheme } from '@material-ui/core/styles';
import { BaseOptionChart } from '../../charts';
import { fNumber } from '../../../utils/formatNumber';
// ----------------------------------------------------------------------


export default function ReportPieChart(props: { labels: Array<string>, data: Array<number>, chartId: string, title: string, colors: string[] }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      id: props.chartId
    },
    labels: (props?.labels || []),
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: false, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => `${fNumber(seriesName)} L`,
        title: {
          formatter: (seriesName: string) => `${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } }
    },
    colors: props?.colors ?? [],
  });

  return (
    <Card>
      <CardHeader title={props.title} subheader={translate("pages.analytics.chart.subheader")} />
      <Box dir="ltr">
        <ReactApexChart
          type="pie"
          series={props?.data || []}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
