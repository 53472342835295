import {
  getAnalyticStats,
  AnalyticStatsParamsInterface,
  ResponseAnalyticsData,
  getReportingStats,
  ResponseReportingData
} from 'api/repositories/getAnalyticStats';
import { useQuery } from 'react-query';

export function useAnalyticStatsQuery(params: AnalyticStatsParamsInterface) {
  return useQuery<ResponseAnalyticsData>({
    queryKey: ['AnalyticStats', JSON.stringify(params)].join('?'),
    queryFn: () => getAnalyticStats(params),
    enabled: params !== undefined,
    refetchOnWindowFocus: false
  });
}

export function useReportingStatsQuery(params: AnalyticStatsParamsInterface) {
  return useQuery<ResponseReportingData>({
    queryKey: ['AnalyticStats', JSON.stringify(params)].join('?'),
    queryFn: () => getReportingStats(params),
    enabled: params !== undefined,
    refetchOnWindowFocus: false
  });
}
