import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import notificationReducer from './slices/notifications';
import locationReducer from './slices/locations';
import chatReducer from './slices/chat';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import kanbanReducer from './slices/kanban';
import calendarReducer from './slices/calendar';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const notificationPersistConfig = {
  key: 'notification',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const locationsPersistConfig = {
  key: 'locations',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  notifications: persistReducer(notificationPersistConfig, notificationReducer),
  locations: persistReducer(locationsPersistConfig, locationReducer),
  chat: chatReducer,
  mail: mailReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,

});

export { rootPersistConfig, rootReducer };
