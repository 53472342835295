import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Link, Typography, LinkProps, Checkbox } from '@material-ui/core';
import useLocales from 'hooks/useLocales';
// utils
import { fDate } from '../../../utils/formatTime';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { SystemNotification } from '../../../@types/notifications';
//
import Label from '../../Label';
import NotificationItemAction from './NotificationItemAction';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 2),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up('md')]: { display: 'flex', alignItems: 'center' },
  '&:hover': {
    zIndex: 999,
    position: 'relative',
    boxShadow: theme.customShadows.z24,
    '& .showActions': { opacity: 1 }
  }
}));

interface WrapStyleProps extends LinkProps {
  component?: ReactNode;
  to?: string;
}

const WrapStyle = styled(Link)<WrapStyleProps>(({ theme }) => ({
  minWidth: 0,
  display: 'flex',
  padding: theme.spacing(2, 0),
  transition: theme.transitions.create('padding')
}));

// ----------------------------------------------------------------------


type NotificationItemProps = {
  notification: SystemNotification;
  isDense: boolean;
  isSelected: boolean;
  onDeselect: VoidFunction;
  onSelect: VoidFunction;
  handleDelete: VoidFunction;
  handleMarkRead: VoidFunction;
};

export default function NotificationItem({
  notification,
  isDense,
  isSelected,
  onSelect,
  onDeselect,
  handleDelete,
  handleMarkRead,
  ...other
}: NotificationItemProps) {
  const { translate } = useLocales();
  const labelColors = {
    info: "#5bc0de",
    warning: "#f0ad4e",
    critical: "#d9534f",
  };
  let labelColor = labelColors.info;
  if (notification.type === 'warning')
    labelColor = labelColors.warning;
  else if (notification.type === 'critical')
    labelColor = labelColors.critical;

  const handleChangeCheckbox = (checked: boolean) => (checked ? onSelect() : onDeselect());

  return (
    <RootStyle
      sx={{
        ...(notification.isRead && {
          color: 'text.primary',
          backgroundColor: 'background.paper'
        }),
        ...(isSelected && { bgcolor: 'action.selected' })
      }}
      {...other}
    >
      <Checkbox
        checked={isSelected}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeCheckbox(event.target.checked)
        }
      />
      <WrapStyle
        color="inherit"
        underline="none"
        component={RouterLink}
        to={`${PATH_DASHBOARD.management.notification.root}/${notification.id}`}
        sx={{ ...(isDense && { py: 1 }) }}
      >
        <Box
          sx={{
            ml: 2,
            minWidth: 0,
            alignItems: 'center',
            display: { md: 'flex' }
          }}
        >
          <Typography
            variant="body2"
            noWrap
            sx={{
              pr: 2,
              minWidth: 100,
              ...(!notification.isRead && { fontWeight: 'fontWeightBold' })
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Label
                sx={{
                  mx: 0.5,
                  textTransform: 'capitalize',
                  bgcolor: labelColor,
                  color: (theme) => theme.palette.getContrastText(labelColor)
                }}
              >
                {translate(`common.notifications.${notification.type}`)}
              </Label>
            </Box>
          </Typography>
          <Typography
            noWrap
            variant="body2"
            sx={{
              pr: 2
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Label
                sx={{
                  mx: 0.5,
                  textTransform: 'capitalize'
                }}
              >
                {notification?.data?.device}
              </Label>
            </Box>
          </Typography>
          <Typography
            noWrap
            variant="body2"
            sx={{
              pr: 2
            }}
          >
            <Box component="span" sx={{ ...(!notification.isRead && { fontWeight: 'fontWeightBold' }) }}>
              {notification?.data?.description}
            </Box>
          </Typography>
          <Typography
            variant="caption"
            sx={{
              minWidth: 120,
              alignContent: 'right',
              textAlign: 'right',
              ...(!notification.isRead && { fontWeight: 'fontWeightBold' })
            }}
          >
            {fDate(notification.createdAt)}
          </Typography>
        </Box>
      </WrapStyle>
      <NotificationItemAction
        className="showActions"
        handleDelete={handleDelete}
        handleMarkRead={handleMarkRead} />
    </RootStyle>
  );
}
