import axiosInstance from 'utils/axios';
import { Building, BuildingsListParams } from '../../@types/sensors';

export interface SensorInterface {
  id: number,
  identifier: string,
  name: string,
  tags: string[]
}

export interface SensorsListInteface {
  sensors: Array<SensorInterface>
}

export const getSensors = async (
  params: BuildingsListParams
): Promise<Array<Building>> => axiosInstance.get(`/devices`, { params });
