// react
import { useState } from 'react';
// material
import { Button, ClickAwayListener, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormGroup, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import { ClientLocation, ClientLocationsState } from '../../@types/location';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const { translate } = useLocales();
  const { locations } = useSelector((state: { locations: ClientLocationsState }) => state.locations);
  const [formSelectedLocation, setFormSelectedLocation] = useState<String[]>(JSON.parse(localStorage.getItem("selected_locations") || "[]"));
  const handleOpen = () => {
    setFormSelectedLocation(JSON.parse(localStorage.getItem("selected_locations") || "[]"));
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const saveChanges = () => {
    localStorage.setItem("selected_locations", JSON.stringify(formSelectedLocation));
    window.location.reload();
  }
  const handleLocationsChange = (event: any) => {
    if (event.target.checked) {
      setFormSelectedLocation([...formSelectedLocation, event.target.value]);
    } else {
      setFormSelectedLocation(formSelectedLocation.filter((identifier: String) =>
        identifier != event.target.value
      ));
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <Button variant="contained" onClick={handleOpen}>
            {translate("components.search_bar.change")}
          </Button>
        )}
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
          <DialogTitle id="customized-dialog-title">
            Select Water Meters
          </DialogTitle>
          <DialogContent dividers>
            <FormControl component="fieldset">
              <FormGroup>
                {
                  locations.map((location: ClientLocation) =>
                    <FormControlLabel
                      key={location.identifier}
                      control={
                        <Checkbox
                          checked={formSelectedLocation.includes(location.identifier)}
                          name={location.identifier}
                          value={location.identifier}
                          onChange={handleLocationsChange}
                        />
                      }
                      label={location.name}
                    />
                  )
                }
              </FormGroup>
              <FormHelperText>
                Choose your water meters and then hit save changes.
              </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={saveChanges} color="primary">
              Save changes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ClickAwayListener>
  );
}
