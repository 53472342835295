import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Tooltip, Typography, IconButton } from '@material-ui/core';
import useLocales from 'hooks/useLocales';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import { fDateTimeSuffix } from '../../../utils/formatTime';
// @types
import { SystemNotification } from '../../../@types/notifications';
//
import { MHidden } from '../../@material-extend';
import Label from '../../Label';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: 84,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  justifyContent: 'space-between'
}));

// ----------------------------------------------------------------------

type NotificationDetailsToolbarProps = {
  notification: SystemNotification;
};

export default function NotificationDetailsToolbar({ notification, ...other }: NotificationDetailsToolbarProps) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const labelColors = {
    info: "#5bc0de",
    warning: "#f0ad4e",
    critical: "#d9534f",
  };
  const [labelColor, setLabelColor] = useState<string>(labelColors.info);
  useEffect(() => {
    if (notification?.type === "warning")
      setLabelColor(labelColors.warning)
    else if (notification?.type === "critical")
      setLabelColor(labelColors.critical);
  }, [notification]);
  return (
    <RootStyle {...other}>
      {notification && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Back">
              <IconButton onClick={() => navigate(`${PATH_DASHBOARD.management.notification.root}`)}>
                <Icon icon={arrowIosBackFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
            <Box sx={{ ml: 2 }}>
              <Typography display="inline" variant="subtitle2">
                <Label
                  sx={{
                    mx: 0.5,
                    textTransform: 'capitalize',
                    bgcolor: labelColor,
                    color: (theme) => theme.palette.getContrastText(labelColor)
                  }}
                >
                  {translate(`common.notifications.${notification?.type}`)}
                </Label> &nbsp; {notification?.data?.description}
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
                {notification.data.device}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MHidden width="smDown">
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {fDateTimeSuffix(notification?.createdAt || new Date())}
              </Typography>
            </MHidden>
            {/* <Tooltip title="More options">
              <IconButton>
                <Icon icon={moreVerticalFill} width={20} height={20} />
              </IconButton>
            </Tooltip> */}
          </Box>
        </>
      )}
    </RootStyle>
  );
}
