import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import { SystemNotification, SystemNotificationState } from '../../@types/notifications';
import {
  getNotifications,
  getNotification,
  deleteNotification,
  deleteNotifications,
  markNotificationRead,
  markNotificationsRead
} from '../../api/repositories/getNotifications';

// ----------------------------------------------------------------------

const initialState: SystemNotificationState = {
  isLoading: false,
  error: false,
  notifications: [],
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    deleteNotification(state, action) {
      state.isLoading = false;
      state.notifications = state.notifications
        .filter((notification: SystemNotification) => notification.id !== action.payload);
    },

    markAsRead(state, action) {
      state.isLoading = false;
      state.notifications = state.notifications
        .filter((notification: SystemNotification) => {
          if (notification.id === action.payload) {
            notification.isRead = !notification.isRead;
          }

          return true;
        });
    },

    clearNotifcations(state) {
      state.notifications = [];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { } = slice.actions;

// ----------------------------------------------------------------------

export function getSystemNotifications() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      getNotifications()
        .then((data) => dispatch(slice.actions.getNotificationsSuccess(data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotificationSuccess(uuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      await getNotification({ uuid })
        .then(() => dispatch(slice.actions.markAsRead(uuid)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteNotificationSuccess(uuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      deleteNotification({ uuid })
        .then(() => {
          dispatch(slice.actions.deleteNotification(uuid));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markNotificationAsRead(uuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      markNotificationRead({ uuid })
        .then(() => {
          dispatch(slice.actions.markAsRead(uuid));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markSelectedNotificationsAsRead(notifications: string[]) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      markNotificationsRead(notifications)
        .then(() => {
          notifications.forEach((notificationUuid) => dispatch(slice.actions.markAsRead(notificationUuid)))
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSelectedNotifications(notifications: string[]) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      deleteNotifications(notifications)
        .then(() => {
          notifications.forEach((notificationUuid) => dispatch(slice.actions.deleteNotification(notificationUuid)))
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearNotifcations() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.clearNotifcations());
  };
}
