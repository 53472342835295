// material
import { Box, Card, Stack, Typography } from '@material-ui/core';
// utils
import useLocales from 'hooks/useLocales';
import { InvoiceType } from 'api/repositories/getAnalyticStats';
import { fCurrency } from '../../../utils/formatNumber';


// ----------------------------------------------------------------------

export default function InvoicePrice(props: { invoicePrice: InvoiceType }) {
  const { translate } = useLocales();
    
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1">{translate("pages.dashboard.cards.total_amount")}</Typography>
        <Typography variant="h4">{fCurrency(props?.invoicePrice?.total_amount)} TND</Typography>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
          <Typography variant="subtitle2">{translate("pages.dashboard.cards.sonede_amount")}</Typography>
          <Typography variant="subtitle2">{fCurrency(props?.invoicePrice?.sonede_amount)} TND</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
          <Typography variant="subtitle2">{translate("pages.dashboard.cards.onas_amount")}</Typography>
          <Typography variant="subtitle2">{fCurrency(props?.invoicePrice?.onas_amount)} TND</Typography>
        </Stack>
      </Box>
    </Card>
  );
}
