import { useEffect, useState } from 'react';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
//
import Markdown from '../../Markdown';
import Scrollbar from '../../Scrollbar';
import NotificaionDetailsToolbar from './NotificationDetailsToolbar';
import { markNotificationAsRead } from '../../../redux/slices/notifications';
import { SystemNotification, SystemNotificationState } from '../../../@types/notifications';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column'
});

const MarkdownWrapperStyle = styled('div')(({ theme }) => ({
  '& > p': {
    ...theme.typography.body1,
    marginBottom: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function NotificationDetails(props: { uuid: string }) {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState<SystemNotification>();
  const { notifications } = useSelector((state: { notifications: SystemNotificationState }) => state.notifications);
  useEffect(() => {
    const notification = notifications.filter((notification: SystemNotification) => notification.id === props?.uuid)[0];
    if (!notification?.isRead)
      dispatch(markNotificationAsRead(props?.uuid));
    setNotification(notification);
  }, [props?.uuid]);

  return (
    <RootStyle>
      {notification && (
        <>
          <NotificaionDetailsToolbar notification={notification} />
          <Divider />
          <Scrollbar sx={{ flexGrow: 1 }}>
            <Box sx={{ p: { xs: 3, md: 5 } }}>
              <MarkdownWrapperStyle>
                <Markdown children={notification?.data?.message || ""} />
              </MarkdownWrapperStyle>
            </Box>
          </Scrollbar>
        </>
      )}
    </RootStyle>
  );
}