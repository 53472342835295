import { useState } from 'react';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
// Hooks
import useLocales from 'hooks/useLocales';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import {
  deleteNotificationSuccess,
  markNotificationAsRead,
  markSelectedNotificationsAsRead,
  deleteSelectedNotifications
} from '../../../redux/slices/notifications';
//
import Scrollbar from '../../Scrollbar';
import EmptyContent from '../../EmptyContent';
import NotificationItem from './NotificationItem';
import { SystemNotification, SystemNotificationState } from '../../../@types/notifications';
import NotificationToolbar from './NotificationToolbar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column'
});

// ----------------------------------------------------------------------

export default function NotificationList() {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { notifications } = useSelector((state: { notifications: SystemNotificationState }) => state.notifications);
  const [selectedNotifications, setSelectedNotifications] = useState<string[]>([]);
  const isEmpty = notifications.length < 1;

  const handleSelectOneNotification = (notificationId: string) => {
    setSelectedNotifications((prevSelectedNotifications) => {
      if (!prevSelectedNotifications.includes(notificationId)) {
        return [...prevSelectedNotifications, notificationId];
      }
      return prevSelectedNotifications;
    });
  };

  const handleDeselectOneNotification = (notificationId: string) => {
    setSelectedNotifications((prevSelectedNotifications) => prevSelectedNotifications.filter((id) => id !== notificationId));
  };

  const handleDelete = (notificationId: string) => {
    dispatch(deleteNotificationSuccess(notificationId));
  }

  const handleDeselectAllNotifications = () => {
    setSelectedNotifications([]);
  };

  const handleSelectAllNotifications = () => {
    setSelectedNotifications(notifications.map(notification => notification.id));
  };

  const handleMarkRead = (notificationId: string) => {
    dispatch(markNotificationAsRead(notificationId));
  }

  const handleSelectedMarkAsRead = () => {
    dispatch(markSelectedNotificationsAsRead(selectedNotifications));
  }

  const handleSelectedDelete = () => {
    dispatch(deleteSelectedNotifications(selectedNotifications));
  }

  return (
    <RootStyle>
      <NotificationToolbar
        notifications={notifications.length}
        selectedNotifications={selectedNotifications.length}
        onSelectAll={handleSelectAllNotifications}
        onDeselectAll={handleDeselectAllNotifications}
        handleSelectedMarkAsRead={handleSelectedMarkAsRead}
        handleSelectedDelete={handleSelectedDelete}
      />

      <Divider />
      {!isEmpty ? (
        <Scrollbar>
          <Box sx={{ minWidth: { md: 800 } }}>
            {notifications.map((notificationItem: SystemNotification) => (
              <NotificationItem
                key={notificationItem.id}
                isDense={false}
                notification={notificationItem}
                isSelected={selectedNotifications.includes(notificationItem.id)}
                onSelect={() => handleSelectOneNotification(notificationItem.id)}
                onDeselect={() => handleDeselectOneNotification(notificationItem.id)}
                handleDelete={() => handleDelete(notificationItem.id)}
                handleMarkRead={() => handleMarkRead(notificationItem.id)}
              />
            ))}
          </Box>
        </Scrollbar>
      ) : (
        <EmptyContent
          title={translate("pages.notifications.no_notification")}
          img="/static/illustrations/illustration_empty_mail.svg"
          sx={{ flexGrow: 1, height: 'auto' }}
        />
      )}
    </RootStyle>
  );
}
