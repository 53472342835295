import { Icon } from '@iconify/react';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import outlineNotificationsOff from '@iconify/icons-ic/outline-notifications-off';
// material
import { styled } from '@material-ui/core/styles';
import { Tooltip, IconButton } from '@material-ui/core';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: 40,
  zIndex: 99,
  opacity: 0,
  margin: 'auto',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  top: theme.spacing(1),
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  justifyContent: 'center',
  padding: theme.spacing(0, 0.75),
  boxShadow: theme.customShadows.z12,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  transition: theme.transitions.create('opacity')
}));

// ----------------------------------------------------------------------

type NotificationtemActionProps = {
  handleDelete?: VoidFunction;
  handleMarkRead?: VoidFunction;
  className?: string;
};

export default function NotificationItemAction({
  handleDelete,
  handleMarkRead,
  ...other
}: NotificationtemActionProps) {

  const { translate } = useLocales();
  const NOTIF_ACTIONS = [
    {
      name: translate('pages.notifications.delete'),
      icon: trash2Fill,
      action: handleDelete
    },
    {
      name: translate('pages.notifications.mark_as_read'),
      icon: outlineNotificationsOff,
      action: handleMarkRead
    }
  ];

  return (
    <RootStyle {...other}>
      {NOTIF_ACTIONS.map((action) => (
        <Tooltip key={action.name} title={action.name}>
          <IconButton
            size="small"
            onClick={action.action}
            sx={{
              mx: 0.75,
              '&:hover': {
                color: 'text.primary'
              }
            }}
          >
            <Icon icon={action.icon} width={24} height={24} />
          </IconButton>
        </Tooltip>
      ))}
    </RootStyle>
  );
}
