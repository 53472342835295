import axiosInstance from 'utils/axios';
import { SystemNotification } from '../../@types/notifications';

interface NotificationParmas {
    uuid: string;
}

export const getNotifications = async (

): Promise<SystemNotification[]> => axiosInstance.get(`/notifications`)
    .then((response) => (
        response.data || []).map((item: any) => (
            {
                id: item.id,
                data: {
                    type: item.data.type,
                    message: item.data.message,
                    device: item.data.device,
                    description: item.data.description,
                },
                isRead: item.read_at !== null,
                createdAt: item.created_at,
                type: item.data.type
            })
        ));

export const getNotification = async (
    params: NotificationParmas
): Promise<SystemNotification> => axiosInstance.get(`/notifications/${params.uuid}`)
    .then((response) => (
        {
            id: response.data.id,
            data: {
                type: response.data.data.type,
                message: response.data.data.message,
                device: response.data.data.device,
                description: response.data.data.description,
            },
            isRead: response.data.read_at !== null,
            createdAt: response.data.created_at,
            type: response.data.data.type
        }
    ));

export const deleteNotification = async (
    params: NotificationParmas
) => axiosInstance.delete(`/notifications`, {
    data: {
        notifications: [params.uuid]
    }
});

export const deleteNotifications = async (
    params: string[]
) => axiosInstance.delete(`/notifications`, {
    data: {
        notifications: params
    }
});

export const markNotificationRead = async (
    params: NotificationParmas
) => axiosInstance.put(`/notifications`, {
    notifications: [params.uuid]
});

export const markNotificationsRead = async (
    params: string[]
) => axiosInstance.put(`/notifications`, {
    notifications: params
});

