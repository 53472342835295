
import axiosInstance from 'utils/axios';
import { SensorCategory } from '../../@types/sensors';
import { NotificationSills, ThresholdReference } from '../../@types/user';


export const getConsumptionSills = async (

): Promise<NotificationSills> => axiosInstance.get(`/notifications/settings`)
    .then((response) => response.data);

export const setConsumptionSills = async (
    params: any
): Promise<any> => axiosInstance.post(`/notifications/settings`, params)
    .then((response) => response.data);

export const getSensorsCategories = async (

): Promise<SensorCategory[]> => axiosInstance.get(`/categories`)
    .then((response) => response.data);

export const getThresholdsReference = async (

): Promise<ThresholdReference[]> => axiosInstance.get(`/devices/thresholds`)
    .then((response) => response.data.map((data: any) => (
        {
            mainSensorCategoryId: data.main_sensor_category_id,
            subSensorCategoryId: data.sub_sensor_category_id,
            minDuration: data.min_duration,
            maxDuration: data.max_duration,
            minConsumption: data.min_consumption,
            maxConsumption: data.max_consumption,
        }
    )));
