import { useParams } from 'react-router-dom';
// material
import { Container, Card } from '@material-ui/core';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import { NotificationList, NotificationDetails } from '../../components/_dashboard/notification';

// ----------------------------------------------------------------------

export default function Notification() {
  const { themeStretch } = useSettings();
  const { notification = '' } = useParams();

  return (
    <Page title={`Notifications | ${process.env.REACT_APP_NAME || "WaterSec"}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Card sx={{ height: { md: '72vh' }, display: { md: 'flex' } }}>
          {notification ? <NotificationDetails uuid={notification} /> : <NotificationList />}
        </Card>
      </Container>
    </Page>
  );
}
