import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@material-ui/core';
import useLocales from 'hooks/useLocales';
import { BaseOptionChart } from '../../charts';
import { ChartData } from '../../../@types/charts';
// ----------------------------------------------------------------------
export default function AnalyticsConsumption(props: { consumption: ChartData, referenceConsumption?: ChartData, action: string }) {
  const { translate } = useLocales();
  const series = [];
  if (props.action === "compare") {
    series.push({
      name: "A",
      data: [(props?.consumption?.consumptions || []).reduce((previousValue, currentValue) => previousValue + currentValue.consumptions.reduce((partialSum, a) => partialSum + a, 0), 0)]
    });
    series.push({
      name: "B",
      data: [(props?.referenceConsumption?.consumptions || []).reduce((previousValue, currentValue) => previousValue + currentValue.consumptions.reduce((partialSum, a) => partialSum + a, 0), 0)]
    });
  }
  else {
    (props.consumption.consumptions || []).map((tagConsumption) => series.push({ name: tagConsumption.tag, data: tagConsumption.consumptions }));
  }
  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      id: 'report_bar_chart',
      type: 'bar',
      stacked: props?.action !== "compare",
    },
    colors: props?.action === "compare" ? ['#5bb6e6', '#52ab32'] : ['#3695f4', '#F44336'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      },
    },
    fill: { type: ['solid', 'solid'] },
    labels: props?.action === "compare" ? ["", ""] : (props.consumption?.dates || []).map((item) => item),
    xaxis: { type: 'date' },
    yaxis: { decimalsInFloat: 0 },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(3)} ${translate('common.liters')}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title={translate("pages.analytics.chart.title")} subheader={translate("pages.analytics.chart.subheader")} />
      <Box dir="ltr">
        {props?.consumption && (
          <ReactApexChart
            type="bar"
            series={series}
            options={chartOptions}
            height={364}
          />
        )}
      </Box>
    </Card>
  );
}
