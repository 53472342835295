import _ from 'lodash';
import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  CardHeader,
  Tooltip
} from '@material-ui/core';
// materials
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack5';
import useLocales from 'hooks/useLocales';
import React, { FC, useEffect, useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import { SensorCategory } from '../../@types/sensors';
import { ConsumptionLevel, NotificationSills, ThresholdReference } from '../../@types/user';
import { getConsumptionSills, setConsumptionSills, getSensorsCategories, getThresholdsReference } from '../../api/repositories/comsumptionSills';

// components
import Page from '../../components/Page';
// hooks
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

interface StateModel {
  loading: boolean;
  error: string;
  notificationSills: NotificationSills | undefined;
  consumptionLevels: Array<ConsumptionLevel>;
  sensorCategories: Array<SensorCategory>;
  thresholdsReferences: Array<ThresholdReference>;
  tags: Array<string>;
}

interface SensorsConsumptionsSillsModel {
  identifier: string;
  name: string;
  tag: string;
  threshold: number | null;
  duration: number | null;
  consumption: number | null;
  mainCategoryId: number | null;
  subCategoryId: number | null;
}

interface InitialValuesModel {
  globalConsumptionThreshold: number;
  restGlobalConsumptionThreshold: number;
  sensorsConsumptionsThresholds: SensorsConsumptionsSillsModel[];
}

interface NotificationSettingsFormPropsModel {
  initialValues: InitialValuesModel;
  notifications: NotificationSills | undefined;
  sensorCategories: SensorCategory[] | [];
  tags: Array<String>;
  thresholdsReferences: Array<ThresholdReference>;
}

const NotificationsSettingsComponent: FC = (): JSX.Element => {
  const [state, setState] = useState<StateModel>({
    loading: false,
    error: '',
    notificationSills: undefined,
    consumptionLevels: [],
    sensorCategories: [],
    thresholdsReferences: [],
    tags: ['hot', 'cold']
  });

  const getSettings = () => {
    setState((state) => ({ ...state, loading: true, error: '' }));
    Promise.all([getConsumptionSills(), getSensorsCategories(), getThresholdsReference()])
      .then((response) => {
        const [notificationSills, sensorCategories, thresholdsReferences] = response;
        setState((state) => ({
          ...state,
          loading: false,
          error: '',
          notificationSills,
          sensorCategories,
          thresholdsReferences
        }));
      })
      .catch((error) => {
        setState((state) => ({ ...state, loading: false, error: 'Error' }));
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  if (state.loading) {
    return <div>Loading....</div>;
  }
  if (state.error !== '') {
    return <div>Error</div>;
  }
  if (!state.loading && state.notificationSills === undefined) {
    return <div>Not Data</div>;
  }
  return (
    <NotificationSettingsForm
      notifications={state.notificationSills}
      sensorCategories={state.sensorCategories}
      tags={state.tags}
      thresholdsReferences={state.thresholdsReferences}
      initialValues={{
        globalConsumptionThreshold: state.notificationSills?.globalConsumptionThreshold || 0,
        restGlobalConsumptionThreshold: 0,
        sensorsConsumptionsThresholds: state?.notificationSills?.sensorsConsumptionsThresholds || [
          {
            identifier: "",
            threshold: 0,
            tag: '',
            name: '',
            duration: null,
            consumption: null,
            mainCategoryId: null,
            subCategoryId: null
          }
        ]
      }}
    />
  );
};

const NotificationSettingsForm: FC<NotificationSettingsFormPropsModel> = ({
  initialValues,
  sensorCategories,
  tags,
  thresholdsReferences
}): JSX.Element => {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, control, setValue, watch, getValues, formState: { errors } } = useForm({
    defaultValues: initialValues,
    shouldUnregister: false
  });

  const { fields } = useFieldArray({
    control,
    name: "sensorsConsumptionsThresholds",
    keyName: 'identifier'
  });
  watch("sensorsConsumptionsThresholds");
  const onSubmit = (data: any) => {
    setConsumptionSills(data)
      .then((response) => {
        enqueueSnackbar('Save success', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      });
  };

  return (
    <Page title="Settings | Water-Sec">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <CardHeader title={translate('pages.settings.title')} subheader={translate('pages.settings.subheader')} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      {...register("globalConsumptionThreshold")}
                      name="globalConsumptionThreshold"
                      type="number"
                      label={translate('pages.settings.form.global_consumption_threshold')}
                    />
                  </Stack>
                  {fields.map((field, index) => {
                    const placeholder = thresholdsReferences.filter(thresholdReference =>
                      (thresholdReference.mainSensorCategoryId === getValues(`sensorsConsumptionsThresholds.${index}.mainCategoryId`)) &&
                      (thresholdReference.subSensorCategoryId === getValues(`sensorsConsumptionsThresholds.${index}.subCategoryId`))
                    )[0] ?? null;
                    const durationPerUsePlaceholder = !placeholder ?
                      '' :
                      translate('pages.settings.form.duration_per_use_placehlder', {
                        min_duration: placeholder.minDuration,
                        max_duration: placeholder.maxDuration
                      });
                    const consumptionPerUsePlaceholder = !placeholder ?
                      '' :
                      translate('pages.settings.form.consumption_per_use_placehlder', {
                        min_consumption: placeholder.minConsumption,
                        max_consumption: placeholder.maxConsumption
                      });
                    return (
                      <div key={field.identifier}>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <TextField
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              label={translate('pages.settings.form.sensor_name')}
                              {...register(`sensorsConsumptionsThresholds.${index}.name`)}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Autocomplete
                              freeSolo
                              fullWidth
                              autoHighlight
                              options={sensorCategories}
                              getOptionLabel={(sensorCategory: SensorCategory) => `${translate(`categories.${sensorCategory.main_category_code}`)}: ${translate(`categories.${sensorCategory.sub_category_code}`)}`}
                              groupBy={(sensorCategory) => translate(`categories.${sensorCategory.main_category_code}`)}
                              renderOption={(props, sensorCategory: SensorCategory) => (
                                <li {...props}>{translate(`categories.${sensorCategory.sub_category_code}`)}</li>
                              )}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={translate('pages.settings.form.category_placeholder')}
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled"
                                  }}
                                />
                              )}
                              onChange={(e, value: any) => {
                                if (value !== null) {
                                  setValue(`sensorsConsumptionsThresholds.${index}.mainCategoryId`, value?.main_category_id);
                                  setValue(`sensorsConsumptionsThresholds.${index}.subCategoryId`, value?.sub_category_id);
                                }
                              }}
                              defaultValue={
                                _.first((sensorCategories)
                                  .filter((sensorCategory) =>
                                    (sensorCategory.main_category_id === field.mainCategoryId) && (sensorCategory.sub_category_id === field.subCategoryId))
                                )}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Autocomplete
                              freeSolo
                              fullWidth
                              options={tags || []}
                              getOptionLabel={tag => translate(`tags.${tag}`)}
                              defaultValue={field.tag}
                              autoHighlight
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={translate('pages.settings.form.tags_placeholder')}
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled"
                                  }}
                                />
                              )}
                              onChange={(e, value: any) => {
                                if (value !== null) {
                                  setValue(`sensorsConsumptionsThresholds.${index}.tag`, value);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              fullWidth
                              type="number"
                              margin="normal"
                              variant="outlined"
                              label={translate('pages.settings.form.threshold')}
                              {...register(`sensorsConsumptionsThresholds.${index}.threshold`)}
                              defaultValue={field.threshold}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title={durationPerUsePlaceholder} placement="right-end">
                              <TextField
                                fullWidth
                                type="number"
                                margin="normal"
                                variant="outlined"
                                placeholder={durationPerUsePlaceholder}
                                label={translate('pages.settings.form.duration_per_use')}
                                {...register(`sensorsConsumptionsThresholds.${index}.duration`)}
                                defaultValue={field.duration || ''}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title={consumptionPerUsePlaceholder} placement="right-end">
                              <TextField
                                fullWidth
                                type="number"
                                margin="normal"
                                variant="outlined"
                                placeholder={consumptionPerUsePlaceholder}
                                label={translate('pages.settings.form.consumption_per_use')}
                                {...register(`sensorsConsumptionsThresholds.${index}.consumption`)}
                                defaultValue={field.duration || ''}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  }
                  )}

                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained">
                      {translate('pages.settings.form.save_changes')}
                    </LoadingButton>
                  </Box>
                </Stack>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default NotificationsSettingsComponent;

