import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
// utils
import Echo from 'laravel-echo';
import useSound from 'use-sound';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/sanctum';
// reducers
import useLocales from '../hooks/useLocales';
import { getSystemNotifications, clearNotifcations } from '../redux/slices/notifications';
import { getLocations, clearLocations, getSelectedLocations } from '../redux/slices/locations';
import { useDispatch } from '../redux/store';
// @types
import { ActionMap, AuthState, AuthUser, SanctumContextType } from '../@types/authentication';

// ----------------------------------------------------------------------

const Io = require('socket.io-client');
const notificationSound = require('../assets/sounds/notification.mp3');

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type SanctumAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type SanctumActions = ActionMap<SanctumAuthPayload>[keyof ActionMap<SanctumAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const SanctumReducer = (state: AuthState, action: SanctumActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<SanctumContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [playbackRate, setPlaybackRate] = useState(0.75);
  const [state, dispatch] = useReducer(SanctumReducer, initialState);
  const dispatchStore = useDispatch();
  const { onChangeLang } = useLocales();
  const [play] = useSound(notificationSound, {
    playbackRate, soundEnabled: true, volume: 0.7
  });
  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axios.get('/auth/user');
        const { user } = response.data;
        onChangeLang(user.locale);
        // const echo = new Echo({
        //   broadcaster: 'socket.io',
        //   host: `${process.env.REACT_APP_WS_URL}`,
        //   client: Io,
        //   auth: { headers: { Authorization: `Bearer ${accessToken}` } },
        // });
        // echo.private(`App.Models.Client.${user.id}`)
        //   .notification((notification: any) => {
        //     dispatchStore(getSystemNotifications());
        //   });
        dispatchStore(getSystemNotifications());
        dispatchStore(getLocations());
        dispatchStore(getSelectedLocations());
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  const login = async (email: string, password: string) => {
    const response = await axios.post('/auth/login', {
      email,
      password
    });

    const { accessToken, user } = response.data;
    setSession(accessToken);
    initialize();
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;
    localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatchStore(clearNotifcations());
    dispatchStore(clearLocations());
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => { };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'sanctum',
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
