import { useQuery } from 'react-query';
import {
  getSensors
} from 'api/repositories/getSensors';
import { Building, BuildingsListParams } from '../../@types/sensors';

export function useFloorsQuery(params: BuildingsListParams) {
  return useQuery<Array<Building>>({
    queryKey: ['getSensors', JSON.stringify(params)].join('?'),
    queryFn: () => getSensors(params),
    enabled: params !== undefined,
    refetchOnWindowFocus: false
  });
}
