import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from '../store';
// utils
import { ClientLocation, ClientLocationsState } from '../../@types/location';
import {
    getLocationsList
} from '../../api/repositories/locations';

// ----------------------------------------------------------------------

const initialState: ClientLocationsState = {
    isLoading: false,
    error: false,
    locations: [],
    selectedLocations: []
};

const slice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },
        getClientLocationsSuccess(state, action) {
            state.locations = action.payload;
            state.isLoading = false;
        },
        markAsSelected(state, action) {
            localStorage.setItem("selected_locations", JSON.stringify(action.payload));
            state.selectedLocations = action.payload;
            state.isLoading = false;
        },
        clearLocations(state) {
            localStorage.setItem("selected_locations", JSON.stringify([]));
            state.selectedLocations = [];
            state.isLoading = false;
        },
        getSelectedLocations(state) {
            state.isLoading = false;
            return JSON.parse(localStorage.getItem("selected_locations") || "[]");
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { } = slice.actions;

// ----------------------------------------------------------------------

export function getLocations() {
    return async () => {
        const { dispatch } = store;
        dispatch(slice.actions.startLoading());
        try {
            getLocationsList()
                .then((data) => dispatch(slice.actions.getClientLocationsSuccess(data)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSelectedLocations() {
    return async () => {
        const { dispatch } = store;
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getSelectedLocations());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function selectLocations(location: ClientLocation[] | []) {
    return async () => {
        const { dispatch } = store;
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.markAsSelected(location));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearLocations() {
    return async () => {
        const { dispatch } = store;
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.clearLocations());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}