// icons
import SettingsIcon from '@material-ui/icons/Settings';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import NotificationsIcon from '@material-ui/icons/Notifications';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';


// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  reporting: getIcon('ic_kanban'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  settings: getIcon('ic_desing'),
  simulation: getIcon('ic_simulation'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'sidebar.subheaders.general',
    items: [
      {
        title: 'sidebar.items.dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'sidebar.items.analytics',
        path: PATH_DASHBOARD.general.analytics,
        icon: ICONS.analytics
      },
      {
        title: 'sidebar.items.comparison',
        path: PATH_DASHBOARD.general.comparison,
        icon: <CompareArrowsIcon />
      },
      {
        title: 'sidebar.items.reporting',
        path: PATH_DASHBOARD.general.reporting,
        icon: ICONS.reporting
      },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'sidebar.subheaders.management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'sidebar.items.user',
        path: PATH_DASHBOARD.management.user,
        icon: ICONS.user
      },
      // MANAGEMENT : Settings
      {
        title: 'sidebar.items.settings',
        path: PATH_DASHBOARD.management.settings,
        icon: <SettingsIcon />
      },
      {
        title: 'sidebar.items.simulation',
        path: PATH_DASHBOARD.management.simulation,
        icon: ICONS.simulation
      },
      {
        title: 'sidebar.items.notifications',
        path: PATH_DASHBOARD.management.notification.root,
        icon: <NotificationsIcon />
      }
    ]
  }
];

export default sidebarConfig;
